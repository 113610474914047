
<script>

import Switches from "vue-switches";
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { authHeader } from '../../../helpers/fakebackend/auth-header';
import   helperFile   from '../../../helpers/helper';
import VueGoogleAutocomplete from 'vue-google-autocomplete';

import {
  required
} from "vuelidate/lib/validators";

/**
 * Advanced-form component
 */
export default {
  page: {
    title: "Add Food Court",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
 
    Switches,
    Multiselect,
    Layout,
    PageHeader,
    VueGoogleAutocomplete
    
  },
  data() {
    return {
      title: "Add Food Court",
      items: [
        {
          text: "Add Food Court",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],

      status :'',
      submitted: false,
      showDismissibleAlert :false,
      loading: false,
      notificationText:"",
      alertvariant:"",
      branches:[],
      branchID:"",
      foodCourtID:"",
      isEdit:false,

    foodCourtTitle:'',
    employees:[],
    empID:"",
    adminName:"",
    adminPhoneNO:"",
    adminEmail:"",
   

posts:{
    address:"",
    addressInput:"",
    state:"",
    country:"",
    pincode:"",
    city:"",
    latitude:"",
    longitude:"",
},
  // table for branch ////
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      selectMode: 'multi',
      selected: [],

      

      fields: [
        {
          key: "selected",
          
          sortable: false,
        },
        
        
        {
          key: "restaurantName",
          sortable: true,
        },
        {
          key: "address",
          
          sortable: true,
        },
        
      ],
       
      
    };
  },

    validations: {

        foodCourtTitle:{
            required,
        },
        branchID:{
            required,
           
        },
        empID:{
            required,
           
        },
        posts:{
            addressInput:{
                // required,
            }
        }

  },
    created(){
            this.foodCourtID = this.$route.params.id;
            if(this.foodCourtID){
              sessionStorage.setItem('foodCourtID',this.foodCourtID);// Save to sessionStorage
            }
            if(this.$route.params.type=='edit'){
              this.isEdit = true;
            }else{
              this.isEdit = false;
            }

         
  },
  methods:{
getAllBranchDetails(){
    this.axios.get(this.$loggedRole+"/getAllBranchDetails")
    .then((result)=>{
    this.branches = result.data.data;
    
        
});
},

openModal(){
    //alert();
    this.$root.$emit("bv::show::modal", "modalBranches");
    this.readRestaurantBranchData();
},

readRestaurantBranchData(){

    this.axios
    .post(
        "getAllRestaurantBranches",
    )
    .then((response) => {
        //Then injecting the result to datatable parameters.

        this.tableData = response.data.data;
        //console.log((response));
        
    });
},

    submitForm(){

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let formData = new FormData();  
           /*
                Add the form data we need to submit
            */     
        formData.append('title', this.foodCourtTitle);
        formData.append('branchID', this.branchID.branchID);
        formData.append('empID',this.empID.empID);
       // formData.append('restBranchIDs',JSON.stringify(this.selected));
        formData.append('addressInput',this.posts.addressInput);
        formData.append('address',this.posts.address);
        formData.append('state',this.posts.state);
        formData.append('city',this.posts.city);
        formData.append('pincode',this.posts.pincode);
        formData.append('country',this.posts.country);
        formData.append('latitude',this.posts.latitude);
        formData.append('longitude',this.posts.longitude);
        formData.append('adminName',this.adminName);
        formData.append('adminPhoneNO',this.adminPhoneNO);
        formData.append('adminEmail',this.adminEmail);
        formData.append('createdBy', this.$storageData.profile.pid);
        formData.append('loginTypeID',this.$storageData.login_type);
        

        //e.preventDefault();
        this.axios.post(this.$loggedRole+"/addFoodCourt",  formData,
        {
          headers: authHeader()
        })
      .then((result)=>{
        //console.log(result);
        if(result.data.status==200){
            this.showDismissibleAlert = true;
            this.loading = false;
            setTimeout(() => {
                this.$router.push({name: 'foodCourtDetails', params: { id: result.data.foodCourtID } });

            },2000);
        }
             
      })
     .catch((error)=> {
              this.loading = false;
             // this.showDismissibleAlert = true;
            //  this.alertvariant = "danger";
              //this.notificationText = error.response.data;
                         //console.error(error.response.data.data);
                         alert(error.response.data.data);
                    });
      }  
    },
updateForm(){
  this.foodCourtID = this.$route.params.id ? this.$route.params.id:sessionStorage.getItem('foodCourtID');

  if(this.foodCourtID=="" || this.foodCourtID== undefined )
  {
    alert('Can not submit the form because food court is not set.');
  }
  else{


        this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let formData = new FormData();  

        formData.append('foodCourtID', this.foodCourtID);
        formData.append('title', this.foodCourtTitle);
        formData.append('branchID', this.branchID.branchID);
        formData.append('empID',this.empID.empID);
        formData.append('addressID',this.allDetailsArr.addressID);
       // formData.append('restBranchIDs',JSON.stringify(this.selected));
        formData.append('addressInput',this.posts.addressInput);
        formData.append('address',this.posts.address);
        formData.append('state',this.posts.state);
        formData.append('city',this.posts.city);
        formData.append('pincode',this.posts.pincode);
        formData.append('country',this.posts.country);
        formData.append('latitude',this.posts.latitude);
        formData.append('longitude',this.posts.longitude);
        formData.append('adminName',this.adminName);
        formData.append('adminPhoneNO',this.adminPhoneNO);
        formData.append('adminEmail',this.adminEmail);
        formData.append('createdBy', this.$storageData.profile.pid);
        formData.append('loginTypeID',this.$storageData.login_type);
        

        //e.preventDefault();
        this.axios.post(this.$loggedRole+"/updateFoodCourt",  formData,
        {
          headers: authHeader()
        })
      .then((result)=>{
        //console.log(result);
        if(result.data.status==200){
            this.showDismissibleAlert = true;
            this.loading = false;
            setTimeout(() => {
                this.$router.push({name: 'foodCourtDetails', params: { id: this.foodCourtID,isEdit: this.isEdit==true ? 1:0,isDelete:sessionStorage.getItem('isDelete')} });
            },2000);
        }
        else{
          this.loading = false;
          alert(result.data.message);
        }


      })
     .catch((error)=> {
              this.loading = false;
                  alert(error.response.data.message);
                    });
      }
  }
},
addNewAddress(){
    this.posts.address = this.posts.addressInput;
},

/**
  * When the location found
  * @param {Object} addressData Data of the found location
  * @param {Object} placeResultData PlaceResult object
  * @param {String} id Input container ID
  */
  getAddressData: function (addressData, placeResultData) {
      this.address = addressData;
      if(placeResultData){
              this.getAllFields = helperFile.getAddressFieldsFromPlacesApi;
              this.getAllFields(placeResultData);
        //console.log(placeResultData);
          this.posts.addressInput = placeResultData.formatted_address;
          this.posts.latitude = placeResultData.geometry.location.lat();
          this.posts.longitude = placeResultData.geometry.location.lng();
      }
      
      
  },

    getCloudkitchStaff(){
    this.axios.get(this.$loggedRole+"/getCloudkitchStaff").then((result)=>{
                this.employees = result.data.data;
                
        });
  },


    onRowSelected(items) {
        this.selected = items;
       // console.log(this.selected);
      },
      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },
      selectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.selectRow(2)
      },
      unselectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.unselectRow(2)
      },

        ///// data table methods
      onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
////// end of datatable methods ///////////////////////////
    getFoodCourtDetailsByID(foodCourtID){
         this.axios
        .post(this.$loggedRole+"/getFoodCourtDetailsByID", {'foodCourtID':foodCourtID} )
        .then((response) => {
          this.allDetailsArr = response.data.data;
          this.foodCourtTitle = this.allDetailsArr.title;
          this.posts.address = this.allDetailsArr.address;
          this.empID = this.allDetailsArr.respArr;
          this.branchID = this.allDetailsArr.locationArr;
          this.sectionArr = response.data.sections;
          if(response.data.adminData != null){
              this.adminPhoneNO = response.data.adminData.phoneNO
              this.adminEmail = response.data.adminData.emailID
              this.adminName = response.data.adminData.name
          }

        });
    },

  },
   
  mounted(){
      this.getAllBranchDetails();
      this.getCloudkitchStaff();
      if(this.isEdit==true && sessionStorage.getItem('foodCourtID') > 0){
          this.getFoodCourtDetailsByID(sessionStorage.getItem('foodCourtID'));
        // this.isEdit = !this.isEdit;        
      }

  },
computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  middleware: "authentication",
};
</script>

<template lang="en">
<Layout>
    <PageHeader :title="title" :items="items" />
  <div class="alertMsg">
    <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
        Food Court Registered Successfully!
    </b-alert>
  </div>
  <!-- <div class="alertMsg">
    <b-alert v-model="showDismissibleAlert" variant="alertvariant" dismissible>
        {{notificationText}}
    </b-alert>
  </div> -->
   
<form @submit.prevent="submitForm" method="post">
      
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                <div class="row">
                        <div class="col-md-6 mb-3">
                            <label class="form-label" for="formrow-title-input">Title *</label>
                            <input type="text" class="form-control" id="formrow-title-input" v-model="foodCourtTitle"
                            :class="{
                                'is-invalid': submitted && $v.foodCourtTitle.$error,
                            }"
                            >
                            <div
                            v-if="submitted && $v.foodCourtTitle.$error"
                            class="invalid-feedback"
                            >
                            <span v-if="!$v.foodCourtTitle.required"
                                >This value is required.</span
                            >
                            </div>
                    </div>
                    <div class="col-md-6">
                       <label class="form-label" for="formrow-desgination-input">Location *</label>
                           <multiselect v-model="branchID" :options="branches" track-by="branchID" label="branchName"  :class="{
                                'is-invalid': submitted && $v.branchID.$error,
                            }"></multiselect>
                        <div
                          v-if="submitted && $v.branchID.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.branchID.required"
                            >This value is required.</span
                          >
                        </div>
                    </div>
                </div>
                 <div class="row">
                  <div class="mb-3 col-md-6">
                    <label>Address*   
                      
                      
                        
                    </label>
                      <vue-google-autocomplete
                              ref="address"
                              id="map"
                              classname="form-control"
                              placeholder="Please type your address"
                              v-on:placechanged="getAddressData"
                              country="in"
                              types="establishment"
                              v-model="posts.address"
                              autocomplete="off"
                              :class="{
                                'is-invalid': submitted && $v.posts.addressInput.$error,
                              }"
                            
                          >
                          </vue-google-autocomplete>
                          <div
                          v-if="submitted && $v.posts.addressInput.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.posts.addressInput.required"
                              >This value is required.</span
                            >
                          </div>

                        
                      </div>
                      <div v-if="this.posts.addressInput" class="col-md-4" style="border: 1px solid #d8d8d8;padding: 10px 28px;">
                       
                          <p>{{this.posts.addressInput}}
                              
                               <span style="position: absolute;right: 3px;bottom: -7px;">
                              <button type="button" v-b-modal.modal-standard class="btn" 
                              style="color: #F3766A !important;font-size: 15px;font-weight: 600;">
                                    <i class="uil uil-plus"></i> Edit
                              </button>
                              </span>
                              </p>
                      </div>
                      
                </div>
           
            
            <b-modal
                id="modal-standard"
                title="Food Court Address"
                title-class="font-18"
                ok-title = "Save"
                @ok="addNewAddress();">
               <div class="mb-3">
               <label>Address</label>
               
                  <textarea
                    v-model="posts.addressInput"
                    class="form-control"
                    name="textarea"
                    rows="3"
                  ></textarea>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-pincode-input">Pincode</label>
                          <input type="text" class="form-control" id="formrow-pincode-input" v-model="posts.pincode"
                         
                          >
                    </div>
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-city-input">City</label>
                          <input type="text" class="form-control" id="formrow-city-input" v-model="posts.city"
                         
                          >
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-state-input">State</label>
                          <input type="text" class="form-control" id="formrow-state-input" v-model="posts.state"
                         
                          >
                    </div>
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-country-input">Country</label>
                          <input type="text" class="form-control" id="formrow-country-input" v-model="posts.country"
                         
                          >
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-latitude-input">Latitude</label>
                          <input type="text" class="form-control" id="formrow-latitude-input" v-model="posts.latitude"
                         
                          >
                    </div>
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-longitude-input">Longitude</label>
                          <input type="text" class="form-control" id="formrow-longitude-input" v-model="posts.longitude"
                         
                          >
                    </div>
                </div>

              </b-modal>
            <div class="row">
              <div class="col-md-6">
                       <label class="form-label" for="formrow-desgination-input">CloudKitch Representative *</label>
                           <multiselect v-model="empID" :options="employees" track-by="empID" label="empName"  :class="{
                                'is-invalid': submitted && $v.empID.$error,
                            }"></multiselect>
                        <div
                          v-if="submitted && $v.empID.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.empID.required"
                            >This value is required.</span
                          >
                        </div>
                </div>
                 <!-- <div class="mb-3 col-md-6 row">
                      <div class="col-md-6">
                          <label class="form-label" for="formrow-firstname-input">Select Restaurant Branches *</label><br>
                         <button type="button" class="btn btn-themeOrange  w-md waves-effect waves-light " @click="openModal();">Restaurant Branches
                           </button> 
                      </div>
                        
                    
                                    <p class="col-md-6" :class="{
                                    'is-invalid': submitted && $v.selected.$error,
                                      }">
                                      Selected branches:<br>
                                      {{ selected.length }}
                                     
                                    </p>
                                      <div
                                          v-if="submitted && $v.selected.$error"
                                          class="invalid-feedback"
                                          >
                                          <span v-if="!$v.selected.required"
                                              >This value is required.</span
                                          >
                                          </div>
        
                </div> -->
            </div>

            </div>
            </div>
           
        </div>


    </div>

        <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                    <h6 class="mb-3"> Admin Details </h6>     
     
  
            <div class="row">
                    <div class="mb-3 col-md-6">
                        <label class="form-label" for="formrow-branchManager-input">Admin Name </label>
                        <input type="text" class="form-control" id="formrow-branchManager-input" v-model="adminName"
                        
                        >
                       
                  </div>

                   <div class="mb-3 col-md-6">
                        <label class="form-label" for="formrow-branchManPhoneNO-input">Admin Mobile No </label>
                        <input type="text" class="form-control" id="formrow-branchManPhoneNO-input"  v-model="adminPhoneNO"
                        
                        >
                       
                       
                  </div>
              </div>
               <div class="mb-3 col-md-6">
                        <label class="form-label" for="formrow-branchManagerEmail-input">Admin Email ID </label>
                        <input type="text" class="form-control" id="formrow-branchManagerEmail-input" v-model="adminEmail"
                       
                        >   
                  </div>   
                    
                    <div class =" row" style="float:right;">
                        <b-spinner v-if="loading"
                        class="m-2 col-3"
                        variant="primary"
                        role="status"
                      ></b-spinner>
                      <button type="button"  @click="isEdit ? updateForm() : submitForm()" 
                        class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
                     
                      </div>

                      </div>
                       
                </div>
              </div>


</form>

             <b-modal
                id="modalBranches"
                title="Restuarant Branches"
                title-class="font-18"
                
               size="lg"
              >
                              <!-- Table -->
            <div class="table-responsive mb-0">
              
                  <p>
                    <b-button size="sm" class="me-2" @click="selectAllRows">Select all</b-button>
                    <b-button size="sm" @click="clearSelected">Clear selected</b-button>
                    
                    </p>
              <b-table
              
                striped hover
                outlined
                bordered
               
                :items="tableData"
                :fields="fields"
                :select-mode="selectMode"
                ref="selectableTable"
                selectable
                @row-selected="onRowSelected"
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                
                
              >
              
            
            <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                <span aria-hidden="true"><i class="far fa-check-square"></i></span>
                <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                <span aria-hidden="true"><i class="far fa-square"></i></span>
                <span class="sr-only">Not selected</span>
                </template>
            </template>
    
   
              
              </b-table>

     
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

              </b-modal>

</Layout>
</template>

<style lang="scss" scoped>

img.preview {
    width: 100px;
    height: 100px;
    background-color: white;
    border: 1px solid #DDD;
    padding: 5px;
    object-fit: contain;
    margin-top: 6px;
}
.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}
</style>


